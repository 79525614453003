import React from "react";
import "../assets/footer.css";

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-40">
                        <div className="footer-text__title">My Contact</div>
                        <div className="footer-text__body">
                            +39 340 857 7123 ilaria.pennati@gmail.com
                        </div>
                    </div>
                    <div className="col-30">
                        <div className="footer-text__title">Quick links</div>
                        <ul className="footer-list text__body">
                            <li>
                                <a href="#home">Home</a>
                            </li>
                            <li>
                                <a href="#features">Why Me</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-30">
                        {/* <div className="footer-text__title">Newsletter</div> */}

                        {/* <div className="footer-input">
                            <input type="text" name="email" placeholder="Email id" />
                            <div className="footer-input__icon">
                                 <ion-icon name="paper-plane-outline" /> 
                                Add
                            </div>
                        </div> */}
                    </div>
                </div>
                 <div className="copyright footer-text__body" style={{fontWeight: '1000'}}> 
                        Not the best, but the authentic work
                        </div>
                <div className="copyright">
                    Copyright © 2024 Ilaria Pennati - All Rights Reserved.
                </div>
            </div>
        </footer>
    );
}

export default Footer;
