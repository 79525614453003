import React from "react";
import preview from "../assets/images/ilaria.png"

function HeroBanner() {
    return (
        <section id="home" style={{ paddingBottom: "10rem" }}>
            <div className="container">
                <div className="home-text">
                    <div className="section-text__title-big">
                        My Portfolio
                    </div>
                    <div className="section-text__body" style={{ color: "white" }}>
                        Welcome to My portfolio, showcasing my diverse skills and experiences in copywriting, graphic design, teaching, translation, digital consulting, and event management. With a strong educational background and a passion for learning, I am dedicated to delivering creative and impactful solutions across various domains.
                    </div>
                    {/* You can add additional sections or buttons here */}
                </div>

                <div className="section-image">
                    <img className="section-image-in" src={preview} alt="Ilaria Pennati" />
                </div>
            </div>
        </section>
    );
}

export default HeroBanner;
