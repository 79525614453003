import React from "react";
function Services() {
    return (
        <section id="services" style={{marginBottom:'450px', marginTop:'90px'}}>
            <div className="section-text">
                <div className="section-text__title-centered">
                    How Can I Help You Achieve Your Goals?
                </div>
                <div className="service-cards">
                    <div className="service-card">
                        <div className="service-card__icon">
                            <ion-icon name="school-outline" />
                        </div>
                        <div className="service-card__text-container">
                            <div className="section-text__title-small">Tailored Teaching</div>
                            <div className="text_body_service">
                                Benefit from personalized teaching sessions tailored to your needs and preferences. With my background as an experienced teacher, I can help you master languages like Italian, English, French, and Spanish, enabling effective communication and fostering multicultural understanding.
                            </div>
                        </div>
                    </div>

                    <div className="service-card active">
                        <div className="service-card__icon">
                            <ion-icon name="construct-outline" />
                        </div>
                        <div className="service-card__text-container">
                            <div className="section-text__title-small">
                                Event Management Expertise
                            </div>
                            <div className="text_body_service">
                                Let me handle the intricacies of event planning and management for you. With over 11 years of experience in event management, I bring creativity, attention to detail, and a passion for creating unforgettable experiences to every event I manage.
                            </div>
                        </div>
                    </div>

                    <div className="service-card">
                        <div className="service-card__icon">
                            <ion-icon name="brush-outline" />
                        </div>
                        <div className="service-card__text-container">
                            <div className="section-text__title-small">Creative Design Solutions</div>
                            <div className="text_body_service">
                                Elevate your brand with authentic graphic design solutions. With my experience in graphic design, I can create visual designs that resonate with your audience and convey your message effectively.
                            </div>
                        </div>
                    </div>
                    
                    <div className="service-card">
                        <div className="service-card__icon">
                            <ion-icon name="fitness-outline" />
                        </div>
                        <div className="service-card__text-container">
                            <div className="section-text__title-small">Yoga and Spirituality</div>
                            <div className="text_body_service">
                                Learn the transformative power of Buddhism and spirituality. As a practitioner of yoga and spirituality, I can guide you on a journey of self-discovery, inner peace, and holistic well-being.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;