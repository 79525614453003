import React from "react";
import "./assets/styles.css";
import Navbar from "./components/Navbar";

import HeroBanner from "./components/HeroBanner";
import Features from "./components/Features";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Download from "./components/Downloads";
import DynamicBackground from "./components/BackgroundDesign";

export default function App() {
  return (
    <>
      <Navbar />
      <main>
        {/* <DynamicBackground /> */}
        <HeroBanner />
        <Services />
                <Features />

        {/* <Screenshots />
        <Pricing /> */}
        {/* <Download /> */}
      </main>
      <Footer />
    </>
  );
}
