import React from "react";

function Features() {
  return (
    <section id="features" style={{ marginBottom: "3rem" }}>
        <div>
          <h2 className="section-text__title feature-header">What I Offer</h2>

        </div>
      <div className="container">
        <div className="section-text" style={{display: "inline-flex"}}>
          <div className="row">
            <div className="col-20">
              <div className="highlight-card">
                <h3 className="highlight-card__title">Simple Solutions</h3>
                <p className="highlight-card__description">
                  I'll simplify your work, making tasks easier and more efficient.
                </p>
              </div>

              <div className="highlight-card">
                <h3 className="highlight-card__title">Versatility</h3>
                <p className="highlight-card__description">
                  I'm adaptable across projects and I easily find connections to make things easy and better.
                </p>
              </div>
            </div>
            </div>

          <div className="row">

            <div className="col-20">
              <div className="highlight-card">
                <h3 className="highlight-card__title">Minimal Carbon Impact</h3>
                <p className="highlight-card__description">
                  My approach minimizes carbon emissions, ensuring almost zero environmental impact.
                </p>
              </div>

              <div className="highlight-card">
                <h3 className="highlight-card__title">Reliable Performance</h3>
                <p className="highlight-card__description">
                  Expect consistent, high-quality work delivered on time and exceeding expectations.
                </p>
              </div>
              </div>


              {/* <div className="highlight-card">
                <h3 className="highlight-card__title">Innovative Ideas</h3>
                <p className="highlight-card__description">
                  I use classic methods to have an authentic impact and use creative concepts in every project.
                </p>
              </div> */}
            </div>
          </div>

        </div>
      {/* </div> */}
    </section>
  );
}

export default Features;
